import React from 'react';

import { RouteComponentProps } from '@reach/router';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';
import MeetingForm from '@presenters/web/components/ClubsMeetingForm';
import { MeetingFormValues } from '@presenters/web/components/ClubsMeetingForm/types';

import { mapMeetingInputToValues, mapMeetingValues } from '@domain/clubs';
import { CREATE_CLUB_MEETING_INITIAL_VALUES } from '@domain/clubs/constants';

import { getBackButtonLabel, getClubDetailsPath } from '@use-cases/clubs';
import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useNotifications } from '@use-cases/notifications';

import { useFetchClubMeeting, useUpdateClubMeeting } from '@repositories/clubs';
import { useAddClubMeeting } from '@repositories/clubs/hooks/useAddClubMeeting';

import { FEATURE_DOMINO_ORGANIZATION, isEnabled } from '@utils/features';
import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  clubId: string;
}

const AddMeeting: React.FC<Props> = ({ clubId }) => {
  const { t, i18n } = useTranslation();
  const { addError, addSuccess } = useNotifications();

  const { data, error, loading } = useFetchClubMeeting(clubId);
  const [updateMeeting, { loading: isCreating }] = useUpdateClubMeeting();
  const [
    addClubMeeting,
    { loading: isAddMeetingCreating },
  ] = useAddClubMeeting();

  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const isFeatureDominoOrganization = isEnabled(FEATURE_DOMINO_ORGANIZATION);

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  if (
    loading ||
    isCreating ||
    error ||
    !data?.clubById?.meetings ||
    isAddMeetingCreating
  ) {
    return <Loading />;
  }

  const { meetings } = data.clubById;
  const { countries } = data;

  const clubDetailsPath = getClubDetailsPath(clubId);

  const handleSubmit = async (values: MeetingFormValues) => {
    try {
      if (isFeatureDominoOrganization) {
        const mutValues = {
          clubId,
          meeting: {
            ...mapMeetingInputToValues(i18n.language, values),
          },
        };
        await addClubMeeting({ variables: mutValues });
      } else {
        const mutValues = {
          clubId,
          meetings: [
            ...meetings.map(mapMeetingValues),
            {
              ...mapMeetingInputToValues(i18n.language, values),
              meetingId: null,
            },
          ],
        };
        await updateMeeting({ variables: mutValues });
      }
      addSuccess(
        t('club-addmeeting.success', 'Success! The meeting has been added')
      );
      getChannel('meeting').postMessage(
        t(
          'club-meeting-tab-update.message',
          'Please refresh this browser tab to see updated information.'
        )
      );
      localizedNavigate(clubDetailsPath);
    } catch (error) {
      addError((error as Error).message);
    }
  };

  const modalOnConfirmHandler = () => {
    localizedNavigate(clubDetailsPath);
  };

  return (
    <div className="mt-4">
      <LinkPrevious
        path=""
        showModal={modalBackHandler}
        label={getBackButtonLabel(t)}
      />
      <Title>{t('club-addmeeting.title', 'Add New Meeting')}</Title>
      <MeetingForm
        initialValues={{ ...CREATE_CLUB_MEETING_INITIAL_VALUES }}
        countries={countries}
        handleSubmit={handleSubmit}
        onCancelClick={modalBackHandler}
      />
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />
    </div>
  );
};

export default AddMeeting;
