import React, { useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import { Button } from '@components/Button';
import Link from '@components/Link';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import NotFound from '@components/Routing/NotFound';
import ResultsListRow from '@presenters/web/components/ClubTabs/MemberResultTable';

import { ASSIGNED_CANDIDATES_TO_CLUB } from '@domain/clubs/index';

import { FEATURE_LEADS_MML, isEnabled } from '@utils/features';
import getDrupalTargetOrigin from '@utils/getDrupalTargetOrigin';
import { localizedNavigate } from '@utils/localized-navigate';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  clubId: string;
}

const ManageMembershipLeadsClubPage: React.FC<Props> = ({ clubId: id }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const navigateToAddMember = () => {
    const getUrl = new URL(window.location.href);
    localizedNavigate(`${getUrl.pathname}/add-member`);
  };

  const isLeadMMLEnabled = isEnabled(FEATURE_LEADS_MML);
  // total members count will be added dynamically after DIS call
  const totalCount = 20;
  const linkForReports = `${getDrupalTargetOrigin()}${currentLanguage}/secure/application/476`;
  const PAGE_SIZE = 10;
  const CURRENT_PAGE = 1;
  const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };
  const getUrl = new URL(window.location.href);
  const navigate = `${getUrl.pathname}/add-member`;
  if (!isLeadMMLEnabled) {
    return <NotFound />;
  }
  return (
    <>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.MML-club',
          'Manage Membership Candidates'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <h2 className="relative top-8 mobile:top-1">
        {t('mml-page-title', 'Manage membership candidates')}
      </h2>
      <div className="flex flex-col">
        <div>
          <Link
            to={linkForReports}
            className="mt-5 block w-fit text-base text-bright-blue-600 active:text-dark-blue-400 font-sans font-medium font-lg link-styles-off"
          >
            {t(
              'mml.club-lead-report',
              'Active and Historic Membership Candidates Report'
            )}
          </Link>
        </div>
        <div>
          <Link
            to={linkForReports}
            className="mt-3 block w-fit text-base text-bright-blue-600 active:text-dark-blue-400 font-sans font-medium font-lg link-styles-off"
          >
            {t(
              'mml.club-membership-summary',
              'Membership Candidates Executive Summary'
            )}
          </Link>
        </div>
      </div>
      <div className="mb-15 mobile:mb-10">
        <Button
          secondary
          small
          className="mb-4 desktop:mb-8 mt-4 desktop:mt-8 mobile:mt-8"
          clickHandler={() => navigateToAddMember()}
        >
          {t('mml.button.add-new-candidate', 'ADD NEW CANDIDATE')}
        </Button>
      </div>
      <ResultsList
        darkBackground
        headerClassName={`px-8 tablet:px-5 tablet:bg-gray-100 `}
        className="-mx-8 tablet:mx-0"
        summary={
          <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
            {t('mml.table.candidates', 'CANDIDATES')} ({totalCount})
          </h2>
        }
      >
        {totalCount > 0 ? (
          ASSIGNED_CANDIDATES_TO_CLUB.map(member => {
            const newMember = { ...member, id };
            return (
              <ResultsListItem key={member.id || ''}>
                <ResultsListRow {...newMember} />
              </ResultsListItem>
            );
          })
        ) : (
          <div>
            <ResultsListItem>
              <p className='text-xs text-gray-600 "'>
                {t(
                  'mml.no-candidate',
                  'You have no membership candidates at this time.'
                )}
                <Link
                  to={navigate}
                  className="inline mt-5 block w-fit text-base text-dark-blue-400 active:text-dark-blue-400 font-sans font-medium font-lg link-styles-off"
                >
                  {t(
                    'mml.link.add-member-candidate',
                    'Start by adding a Member Candidate now.'
                  )}
                </Link>
              </p>
            </ResultsListItem>
          </div>
        )}
      </ResultsList>
      {totalCount > 0 && (
        <Pagination
          pageSize={PAGE_SIZE}
          page={currentPage}
          totalCount={totalCount}
          pageHandler={pageHandler}
        />
      )}
    </>
  );
};
export default ManageMembershipLeadsClubPage;
