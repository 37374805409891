import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import { isEmpty, values as objectValues } from 'lodash';

import Divider from '@components/Divider';
import AddressFields from '@components/Formik/Address';
import TextField from '@components/Formik/PasswordField';
import PhoneInput from '@components/Formik/PhoneInput';
import LanguageSelect from '@components/Formik/Select/LanguageSelect';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import ClubCTAButtons from '@presenters/web/components/ClubsSaveChangesButtons';

import editClubContactValidationSchema from './validation';

import {
  ClubContactInfoFormValues,
  GetClubContactInfoValues,
  mapContactsInputToValues,
  mapContactsValuesToFormInput,
} from '@domain/clubs';

import { getBackButtonLabel, getClubDetailsPath } from '@use-cases/clubs';
import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling } from '@use-cases/notifications';

import {
  useFetchClubContacts,
  useUpdateClubContacts,
} from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  clubId: string;
}

const EditClubContact: React.FC<Props> = ({ clubId }) => {
  const { t } = useTranslation();

  const { data, loading, error } = useFetchClubContacts(clubId);
  const [
    updateClubContactInfo,
    { loading: isUpdating },
  ] = useUpdateClubContacts();

  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  useErrorHandling(error?.message, !!error);

  if (loading || error || !data?.club || isUpdating) {
    return <Loading />;
  }

  const clubDetailsPath = getClubDetailsPath(clubId);

  const initialValues = mapContactsValuesToFormInput(
    data.club as GetClubContactInfoValues
  );

  const handleSubmit = async (values: ClubContactInfoFormValues) => {
    const mutationValues = mapContactsInputToValues(values, initialValues);

    // map to contact update format
    // each field - check if previous values contains changed

    await updateClubContactInfo({
      variables: {
        clubId,
        ...mutationValues,
      },
    });

    getChannel('contact').postMessage(
      t(
        'club-contact-tab-update.message',
        'Please refresh this browser tab to see updated information.'
      )
    );
    localizedNavigate(clubDetailsPath);
  };

  const handleBackRedirect = () =>
    localizedNavigate(getClubDetailsPath(clubId));

  const modalOnConfirmHandler = () => {
    handleBackRedirect();
  };

  const clearButtonStyle: string = 'absolute -mt-12 -ml-16';

  return (
    <div className="mt-4">
      <LinkPrevious
        path=""
        showModal={modalBackHandler}
        label={getBackButtonLabel(t)}
        classes="mt-4"
      />
      <h2 className="my-3">
        {t('club-editcontact.title', 'Edit Contact Information')}
      </h2>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={editClubContactValidationSchema(t)}
      >
        {({ values, isSubmitting, isValid, dirty }) => {
          const isPrimaryPhoneRequired = !!values.primaryPhone?.number;
          const isPrimaryFaxRequired = !!values.primaryFax?.number;

          const isPrimaryPhoneClearButtonDisabled = objectValues(
            values.primaryPhone
          ).every(isEmpty);
          const isPrimarFaxClearButtonDisabled = objectValues(
            values.primaryFax
          ).every(isEmpty);
          const isAddressClearButtonDisabled = objectValues(
            values.address
          ).every(isEmpty);

          const primaryPhoneLabels = {
            phoneCode: t(
              'club-editcontact.phone-label-extension',
              'Phone Extension'
            ),
            phoneExtension: t('form.address.phone-extension', 'Extension'),
            phoneNumber: t('club-editcontact.phone-label', 'Phone'),
          };

          const primaryFaxLabels = {
            phoneCode: t(
              'club-editcontact.fax-label-extension',
              'Fax Extension'
            ),
            phoneExtension: t('form.address.phone-extension', 'Extension'),
            phoneNumber: t('club-editcontact.fax-label', 'Fax'),
          };

          return (
            <Form className="max-w-lg mt-6">
              <h2 className="mt-2 desktop:mt-6">
                {t('contact.us.form.email-label', 'Email')}
              </h2>
              <TextField
                name="primaryEmail"
                label={t('club-editcontact.email-label', 'Email')}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('edit-personal-details.subheader-phone', 'Phone')}
              </h2>
              <PhoneInput
                suffix="primaryPhone"
                labels={primaryPhoneLabels}
                isCountryFlagRequired={isPrimaryPhoneRequired}
                displayClearButton
                isClearButtonDisabled={isPrimaryPhoneClearButtonDisabled}
                clearButtonStyle={clearButtonStyle}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('club-editcontact.fax', 'Fax')}
              </h2>
              <PhoneInput
                suffix="primaryFax"
                labels={primaryFaxLabels}
                isCountryFlagRequired={isPrimaryFaxRequired}
                displayClearButton
                isClearButtonDisabled={isPrimarFaxClearButtonDisabled}
                clearButtonStyle={clearButtonStyle}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('club-editcontact.website-label', 'Website')}
              </h2>
              <TextField
                name="primaryWebsite"
                label={t('club-editcontact.website-label', 'Website')}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">
                {t('form.address.address-label', 'Address')}
              </h2>
              <AddressFields
                countryId={values.address?.countryId}
                mainLabel={t(
                  'club-editcontact.address-label',
                  'Mailing address'
                )}
                name={{ hasStates: 'address.hasStates' }}
                displayClearButton
                isClearButtonDisabled={isAddressClearButtonDisabled}
                clearButtonStyle={clearButtonStyle}
              />
              <Divider size={8} />
              <h2 className="mt-2 desktop:mt-6">{t('language', 'Language')}</h2>
              <LanguageSelect
                name="officialLanguage.languageId"
                label={t('club-editcontact.languages-label', 'Rotary language')}
                required
                disabled
              />
              <ClubCTAButtons
                isSubmitting={isSubmitting}
                isValid={isValid && dirty}
                onCancelClick={modalBackHandler}
              />
            </Form>
          );
        }}
      </Formik>
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />
    </div>
  );
};

export default EditClubContact;
